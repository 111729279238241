<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="listQuery.ZoneID" :placeholder="'ZoneID'" clearable style="width: 120px" class="filter-item">
        <el-option v-for="item in ZoneIDOptions" :key="item" :label="item" :value="item" />
      </el-select>
      <el-select v-model="listQuery.ServerLabel" :placeholder="'ServerLabel'" clearable class="filter-item"
        style="width: 130px">
        <el-option v-for="item in ServerLabelOptions" :key="item.key" :label="item.name + '(' + item.key + ')'"
          :value="item.key" />
      </el-select>
      <el-select v-model="listQuery.State" :placeholder="'State'" clearable class="filter-item" style="width: 130px">
        <el-option v-for="item in StateOptions" :key="item.key" :label="item.name + '(' + item.key + ')'"
          :value="item.key" />
      </el-select>
      <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        {{ $t("table.search") }}
      </el-button>
      <el-button class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-edit" @click="handleCreate">
        {{ $t("table.add") }}
      </el-button>
    </div>
    <el-table :key="tableKey" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;">
      <el-table-column :label="$t('table.id')" prop="id" align="center" width="80">
        <template #default="{ row }">
          <span>{{ row.ServerID }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('server_manage.servername')" width="180px" align="center">
        <template #default="{ row }">
          <span>{{ row.ServerName }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('server_manage.createtime')" width="180px" align="center">
        <template #default="{ row }">
          <span>{{ new Date(Number(row.CreateTime)).toLocaleString() }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="'ZoneId'" width="180px" align="center">
        <template #default="{ row }">
          <span>{{ row.ZoneID }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('server_manage.serverlabel')" width="180px" align="center">
        <template #default="{ row }">
          <span>{{ row.ServerLabel }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="'State'" width="180px" align="center">
        <template #default="{ row }">
          <span>{{ row.State }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('table.actions')" align="center" width="300" class-name="fixed-width">
        <template #default="{ row }">
          <el-button type="primary" size="mini" @click="handleUpdate(row)">
            Edit
          </el-button>
          <el-button v-if="row.State.includes(2)" size="mini" type="success" @click="handleModifyStatus(row, true)">
            Start
          </el-button>
          <el-button v-if="row.State.includes(1)" size="mini" type="danger" @click="handleModifyStatus(row, false)">
            Stop
          </el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-pagination :total="total" v-show="total > 0" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
      @current-change="searchZoneList" :current-page="listQuery.page" layout="total, prev, pager, next, jumper"
      :page-size="listQuery.limit" />

    <el-dialog :title="'Create Server'" v-model="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="tempServerData" label-position="left" label-width="100px"
        style="width: 400px; margin-left:50px;">
        <el-form-item :label="$t('server_manage.createtime')" prop="timestamp">
          <el-date-picker v-model="tempServerData.createtime" type="datetime" placeholder="Please pick a date" />
        </el-form-item>
        <el-form-item :label="$t('server_manage.servername')" prop="title">
          <el-input v-model="tempServerData.ServerName" />
        </el-form-item>

        <el-form-item :label="'ZoneId'" prop="title">
          <el-select v-model="tempServerData.ZoneID" :placeholder="'ZoneID'" clearable style="width: 120px"
            class="filter-item">
            <el-option v-for="item in ZoneIDOptions" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('server_manage.serverlabel')" prop="title">
          <el-tree ref="Labeltree" :check-strictly="checkStrictly" :data="tempServerData.ServerLabel"
            :props="defaultProps" show-checkbox node-key="path" class="permission-tree" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          {{ $t("table.cancel") }}
        </el-button>
        <el-button type="primary" @click="createServerData()">
          {{ $t("table.confirm") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'Edit Server'" v-model="dialogPageviewsVisible">
      <el-form ref="editForm" :rules="rules" :model="tempServerData" label-position="left" label-width="100px"
        style="width: 400px; margin-left:50px;">
        <el-form-item :label="$t('server_manage.servername')" prop="title">
          <el-input v-model="tempServerData.ServerName" />
        </el-form-item>

        <el-form-item :label="$t('server_manage.serverlabel')" prop="title">
          <el-tree ref="EditLabeltree" :check-strictly="checkStrictly" :data="tempServerData.ServerLabel"
            :props="defaultProps" show-checkbox node-key="path" class="permission-tree" />
        </el-form-item>

        <el-form-item :label="'State'" prop="title">
          <el-tree ref="EditStatetree" :check-strictly="checkStrictly" :data="tempServerData.State" :props="defaultProps"
            show-checkbox node-key="path" class="permission-tree" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogPageviewsVisible = false">
          {{ $t("table.cancel") }}
        </el-button>
        <el-button type="primary" @click="handleEditServer()">
          {{ $t("table.confirm") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'Manage Server'" v-model="dialogManageVisible">
      <el-form ref="manageForm" :model="tempServerData" label-position="left" label-width="100px"
        style="width: 400px; margin-left:50px;">
        <el-form-item :label="$t('server_manage.worktime')" prop="timestamp">
          <el-date-picker v-model="tempServerData.createtime" type="datetime" placeholder="Please pick a date" />
        </el-form-item>
        <el-form-item :label="$t('server_manage.servername')" prop="title">
          {{ tempServerData.ServerName }}
        </el-form-item>

        <el-form-item :label="'Operate'" prop="title">
          {{ operateDes }}
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogManageVisible = false">
          {{ $t("table.cancel") }}
        </el-button>
        <el-button type="primary" @click="handleManageServer()">
          {{ $t("table.confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { ServerManage_GMAddNewServerZone, ServerManage_GMEditServerZone, ServerManage_GMGetServerZoneInfo, ServerManage_GMSearchServerZone } from "@/apis/server_manage";
import { TodayStart } from "@/utils/timer";
import { ElForm, ElMessage, ElMessageBox, ElTree } from "element-plus";
import { cloneDeep } from "lodash";
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

  setup() {
    const { t } = useI18n()
    const dataForm = ref<typeof ElForm>()
    const Labeltree = ref<typeof ElTree>()
    const EditLabeltree = ref<typeof ElTree>()
    const EditStatetree = ref<typeof ElTree>()
    const dataMap = reactive(new ViewData())
    const getZoneDBInfo = async function () {
      dataMap.zonedbInfoLoading = true;
      let cbmsg = await ServerManage_GMGetServerZoneInfo();
      if (cbmsg?.Error !== 0) {
        ElMessage.error(`GetzoneDBFail: ${cbmsg?.Message}`);
        return;
      }
      const items = JSON.parse(cbmsg?.Message!) as IZoneInfoData;
      items.ZoneInfo.forEach(element => {
        dataMap.ZoneIDOptions.push(element);
      });
      for (let k in items.ServerLabel) {
        dataMap.ServerLabelOptions.push({ key: items.ServerLabel[k], name: k });
      }
      for (let k in items.ServerState) {
        dataMap.StateOptions.push({ key: items.ServerState[k], name: k });
      }

      dataMap.zonedbInfoLoading = false;
      await searchZoneList();
    }

    const searchZoneList = async function (page?: number) {
      if (page != null) {
        dataMap.listQuery.page = page;
      }
      dataMap.listLoading = true;
      dataMap.list = [];
      let msg = {} as IC2G_GMSearchServerZone;
      msg.PageCount = dataMap.listQuery.limit;
      msg.PageIndex = dataMap.listQuery.page;

      if (dataMap.listQuery.ZoneID) {
        msg.ZoneId = dataMap.listQuery.ZoneID;
      }
      if (dataMap.listQuery.State) {
        msg.State = dataMap.listQuery.State;
      }
      if (dataMap.listQuery.ServerLabel) {
        msg.ServerLabel = dataMap.listQuery.ServerLabel;
      }
      let cbmsg = await ServerManage_GMSearchServerZone(
        msg
      );
      if (cbmsg?.Error !== 0) {
        ElMessage.error(`GetZoneFail: ${cbmsg?.Message}`);
        return;
      }
      for (let k of cbmsg.SearchResult) {
        let record = JSON.parse(k) as IServerZoneData;
        dataMap.list.push(record);
      }
      dataMap.total = cbmsg.SearchCount;
      dataMap.listLoading = false;
    }
    const resetTempServerData = function () {
      dataMap.tempServerData = cloneDeep(dataMap.defaultServerData);
      dataMap.ServerLabelOptions.forEach(s => {
        dataMap.tempServerData.ServerLabel.push({
          title: `${s.name}(${s.key})`,
          path: s.key
        });
      });
      dataMap.StateOptions.forEach(s => {
        if (s.key > 8) {
          dataMap.tempServerData.State.push({
            title: `${s.name}(${s.key})`,
            path: s.key
          });
        }
      });
    }
    const handleCreate = function () {
      resetTempServerData();
      dataMap.dialogFormVisible = true;
      nextTick(() => {
        (dataForm.value as any).clearValidate()
      });
    }

    const createServerData = function () {
      (dataForm.value as any).validate(async (valid: any) => {
        if (valid) {
          const serverData = dataMap.tempServerData;
          if (!serverData.ZoneID) {
            return;
          }
          if (
            !serverData.ServerName ||
            serverData.ServerName.length < 5 ||
            serverData.ServerName.length > 20
          ) {
            return;
          }
          if (!serverData.createtime) {
            return;
          }
          ElMessageBox.confirm(
            `Confirm to create Server [${serverData.ServerName}] at ${serverData.createtime} ?`,
            "Warning",
            {
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              type: "warning"
            }
          )
            .then(async () => {
              let msg = {} as IC2G_GMAddNewServerZone;
              msg.ZoneId = serverData.ZoneID;
              msg.ServerName = serverData.ServerName;
              msg.OperateTime = Math.ceil(serverData.createtime.getTime() / 1000);
              const checkedlabelKeys = (Labeltree.value as any).getCheckedKeys() as number[];
              msg.ServerLabel = 0;
              checkedlabelKeys.forEach(s => {
                msg.ServerLabel += s;
              });
              let cbmsg = await ServerManage_GMAddNewServerZone(msg);
              dataMap.dialogFormVisible = false;
              if (cbmsg!.Error !== 0) {
                ElMessage.error("create server fail \n" + cbmsg!.Message)
                return;
              }
              dataMap.listQuery.ZoneID = null;
              dataMap.listQuery.State = null;
              dataMap.listQuery.ServerLabel = null;
              await searchZoneList();
              ElMessage.success("create server SUCCESS");
            })
            .catch(err => {
              console.error(err);
            });
        }
      });
    }

    const handleFilter = function () {
      dataMap.listQuery.page = 1;
      searchZoneList();
    }

    const handleUpdate = function (row: IServerZoneData) {
      dataMap.currentEditRow = row;
      resetTempServerData();
      dataMap.tempServerData.ID = row.Id;
      dataMap.tempServerData.ZoneID = row.ZoneID;
      dataMap.tempServerData.ServerName = row.ServerName;
      dataMap.checkStrictly = true;
      dataMap.dialogPageviewsVisible = true;
      nextTick(() => {
        (EditLabeltree.value as any).setCheckedKeys(row.ServerLabel);
        (EditStatetree.value as any).setCheckedKeys(row.State);
        // set checked state of a node not affects its father and child nodes
        dataMap.checkStrictly = false;
      });
    }
    const handleModifyStatus = function (row: any, status: boolean) {
      dataMap.currentEditRow = row;
      resetTempServerData();
      if (status) {
        dataMap.operateDes = "start";
      } else {
        dataMap.operateDes = "stop";
      }
      dataMap.tempServerData.ID = row.Id;
      dataMap.tempServerData.ZoneID = row.ZoneID;
      dataMap.tempServerData.ServerName = row.ServerName;
      dataMap.dialogManageVisible = true;
    }
    const handleEditServer = async function () {
      //更新
      const serverData = dataMap.tempServerData;
      if (!serverData.ID || serverData.ID.length < 5) {
        return;
      }
      ElMessageBox.confirm(
        `Confirm to Edit Server [${serverData.ServerName}] ?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(async () => {
          let msg = {} as IC2G_GMEditServerZone;
          msg.OperateType = 0;
          msg.ServerId = serverData.ID;
          let isChange = false;
          if (dataMap.currentEditRow.ServerName != serverData.ServerName) {
            msg.ServerName = serverData.ServerName;
            isChange = true;
          }
          const checkedlabelKeys = (EditLabeltree.value as any).getCheckedKeys() as number[];
          let count1 = 0;
          let count2 = 0;
          checkedlabelKeys.forEach(s => {
            count1 += s;
          });
          dataMap.currentEditRow.ServerLabel.forEach(s => {
            count2 += s;
          });
          if (count1 != count2) {
            isChange = true;
          }
          msg.ServerLabel = count1;
          const checkedstateKeys = (EditStatetree.value as any).getCheckedKeys() as number[];
          let count3 = 0;
          let count4 = 0;
          checkedstateKeys.forEach(s => {
            count3 += s;
          });
          dataMap.currentEditRow.ServerLabel.forEach(s => {
            if (s > 8) {
              count4 += s;
            }
          });
          if (count3 != count4) {
            isChange = true;
          }
          msg.State = count3;
          if (!isChange) {
            dataMap.dialogPageviewsVisible = false;
            return;
          }
          let cbmsg = await ServerManage_GMEditServerZone(msg);
          dataMap.dialogPageviewsVisible = false;
          if (cbmsg!.Error !== 0) {
            ElMessage.error("edit server fail \n" + cbmsg!.Message)
            return;
          }
          dataMap.listQuery.ZoneID = null;
          dataMap.listQuery.State = null;
          dataMap.listQuery.ServerLabel = null;
          await searchZoneList();
          ElMessage.success(`edit success`);
        })
        .catch(err => {
          dataMap.dialogPageviewsVisible = false;
          console.error(err);
        });
      dataMap.dialogPageviewsVisible = false;
    }

    const handleManageServer = async function () {
      //更新
      const serverData = dataMap.tempServerData;
      if (!serverData.ID || serverData.ID.length < 5) {
        return;
      }
      if (!serverData.createtime) {
        return;
      }
      ElMessageBox.confirm(
        `Confirm to ${dataMap.operateDes} Server [${serverData.ServerName}] at ${serverData.createtime} ?`,
        "Warning",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(async () => {
          let msg = {} as IC2G_GMEditServerZone;
          msg.ServerId = serverData.ID;
          if (dataMap.operateDes == "start") {
            msg.OperateType = 1;
          } else if (dataMap.operateDes == "stop") {
            msg.OperateType = 2;
          }
          msg.OperateTime = Math.ceil(serverData.createtime.getTime() / 1000);
          let cbmsg = await ServerManage_GMEditServerZone(msg);
          dataMap.dialogManageVisible = false;
          if (cbmsg!.Error !== 0) {
            ElMessage.error(`${dataMap.operateDes} server fail \n` + cbmsg!.Message)
            return;
          }
          dataMap.listQuery.ZoneID = null;
          dataMap.listQuery.State = null;
          dataMap.listQuery.ServerLabel = null;
          await searchZoneList();
          ElMessage.success(`${dataMap.operateDes} + " server"`);
        })
        .catch(err => {
          dataMap.dialogManageVisible = false;
          console.error(err);
        });
      dataMap.dialogManageVisible = false;
    }
    onMounted(() => {
      getZoneDBInfo()
    })

    return {
      t, ...toRefs(dataMap),
      dataForm,
      Labeltree,
      EditLabeltree,
      EditStatetree,
      getZoneDBInfo,
      searchZoneList,
      resetTempServerData,
      handleCreate,
      createServerData,
      handleFilter,
      handleUpdate,
      handleModifyStatus,
      handleEditServer,
      handleManageServer,
    }
  }
});

class ViewData {
  public tableKey = 0;
  public list: IServerZoneData[] = [];
  public total = 0;
  public listLoading = true;
  public zonedbInfoLoading = false;
  public checkStrictly = false;
  public listQuery = {
    page: 1,
    limit: 20,
    ZoneID: null as any,
    State: null as any,
    ServerLabel: null as any
  };
  public defaultProps = {
    children: "children",
    label: "title"
  };
  public ZoneIDOptions: number[] = [];
  public StateOptions: IEnumData[] = [];
  public ServerLabelOptions: IEnumData[] = [];
  public readonly defaultServerData = {
    ID: "" as string,
    ZoneID: null as any,
    State: [] as any[],
    ServerLabel: [] as any,
    ServerName: null as any,
    createtime: TodayStart()
  };
  public dialogFormVisible = false;
  public dialogPageviewsVisible = false;
  public dialogManageVisible = false;
  public operateDes = "";

  public rules = {
    ZoneID: [
      { required: true, message: "ZoneID is required", trigger: "change" }
    ],
    ServerName: [
      { required: true, message: "title is required", trigger: "change" }
    ]
  };

  public tempServerData = {
    ID: "" as string,
    ZoneID: null as any,
    State: [] as any[],
    ServerLabel: [] as any[],
    ServerName: null as any,
    createtime: null as any
  };
  public currentEditRow!: IServerZoneData;

}
</script>
